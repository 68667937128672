<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button
            :text="'Zurück'"
            default-href="/dash"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>Kontaktieren Sie uns</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item>
          <ion-label position="floating">Vorname*</ion-label>
          <ion-input v-model="form.contact_vorname" required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Nachname*</ion-label>
          <ion-input v-model="form.contact_nachname" required="true"></ion-input>
        </ion-item>
         <ion-item>
          <ion-label position="floating">Firma</ion-label>
          <ion-input v-model="form.contact_firma" required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Email*</ion-label>
          <ion-input v-model="form.contact_email" type="email" required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Textfeld*</ion-label>
          <ion-textarea
            rows="8"
            placeholder="Ihre Nachricht"
            v-model="form.contact_message"
          ></ion-textarea>
        </ion-item>
        <ion-button
          @click="sendForm()"
          expand="block"
          fill="solid"
          class="ion-padding"
          >Senden</ion-button
        >
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  alertController
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "List",
  data() {
    return {
      form: {
        contact_vorname: this.$store.state.user.firstname,
        contact_nachname: this.$store.state.user.lastname,
        contact_email: this.$store.state.user.email,
        contact_firma: this.$store.state.user.display_name
      },
    };
  },
  methods: {
    async presentSuccessConfirm() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Nachricht wurde versendet!',
          message: 'Wir melden uns bald möglichst bei Ihnen.',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
                this.$router.push('/');
              },
            },
          ],
        });
      return alert.present();
    },
    async presentErrorAlert(err) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Fehler',
          subHeader: 'Irgendetwas hat nicht geklappt:',
          message: err.data.message,
          buttons: ['OK'],
        });
      await alert.present();

      await alert.onDidDismiss();
    },
    async sendForm() {
      try { 
        await this.$http.post(`igpapp/v1/sendmail`, this.form);
        this.presentSuccessConfirm()
      } catch (err) {
        this.presentErrorAlert(err.response)
      }
      // const res = await this.$http.post(`igpapp/v1/sendmail`, this.form);
      // if(res.status == 200) this.presentSuccessConfirm()
      // else this.presentErrorAlert(res)
    },
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
  },
});
</script>

<style scoped>
ion-back-button::part(text) {
  color: black;
}
</style>