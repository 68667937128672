import { createStore } from 'vuex';

export default createStore({
	state: {
		loggedIn: localStorage.getItem('loggedIn') || false,
		user: JSON.parse(localStorage.getItem('user')) || {},
		menuVisible: false,
		loading: false
	},
    mutations: {
		setMenu(state, menu) {
			state.menuVisible = menu
		},
		setLoading(state, loading) {
			state.loading = loading
		},
        login(state, user) {
            state.user = user;
            state.loggedIn = true;
        },
        logout(state) {
            state.loggedIn = false;
        },
	},
    actions: {
        login({commit}, user) {
            commit('login', user)
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('loggedIn', true);
		},
        logout({commit}) {
			commit('logout')
			localStorage.removeItem('user');
            localStorage.removeItem('loggedIn');
            return true
		},
        updateMenu({ commit}, value) {
			commit('setMenu', value)
		},
    },
    getters: {
        isLoggedIn: state => !!state.loggedIn,
    }
})