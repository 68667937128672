<template>
  <ion-page>
    <ion-header :translucent="true" v-if="!platform.includes('mobile')">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="black"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="greeting" class="hidden-lg-up">
        <div class="container">
          <h1>
            Willkommen, {{ $store.state.user.firstname }}
            {{ $store.state.user.lastname }}!
          </h1>
        </div>
      </div>

      <div class="container ion-padding-top ion-padding-horizontal">
        <h3>Finde alle Infos:</h3>
      </div>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-button expand="block" fill="solid" href="/list/laender">
                <div class="btn-inner">
                  <ion-icon :icon="globeOutline"></ion-icon>
                  <label>Länder</label>
                </div>
              </ion-button>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-button expand="block" fill="solid" href="/list/themen">
                <div class="btn-inner">
                  <ion-icon :icon="listOutline"></ion-icon>
                  <label>Themen</label>
                </div>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div>
              <ion-button
                expand="block"
                fill="solid"
                href="/form"
                class="dash-button"
              >
                <div class="btn-inner">
                  <ion-icon :icon="createOutline"></ion-icon>
                  <label>Formular</label>
                </div>
              </ion-button>
            </div>
          </ion-col>
          <ion-col> </ion-col>
        </ion-row>
      </ion-grid>
      <!-- <div class="expiry" v-if="!$store.state.menuVisible">Plan gültig bis: {{new Date ($store.state.user.login_expiration_date * 1000).toLocaleDateString('de-de')}}</div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  getPlatforms,
} from "@ionic/vue";

import { globeOutline, listOutline, createOutline } from "ionicons/icons";

export default {
  name: "List",
  data() {
    return {};
  },
  methods: {},
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonMenuButton,
    IonButtons,
    IonToolbar,
    IonHeader,
  },
  setup() {
    return {
      globeOutline,
      listOutline,
      createOutline,
    };
  },
  computed: {
    platform() {
      return getPlatforms();
    },
  },
};
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
}

#greeting {
  background: url(/assets/igp-background.jpeg);
  height: 300px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#greeting .container {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

h1 {
  font-weight: bold;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ion-button {
  height: 4rem;
}

.btn-inner {
  flex-flow: column;
  display: flex;
  align-items: center;
}

.expiry {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  font-size: 10px;
}
</style>