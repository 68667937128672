<template>
  <IonApp>
    <IonSplitPane
      content-id="main-content"
      @ion-split-pane-visible="menuVisible"
    >
      <ion-menu
        content-id="main-content"
        type="overlay"
        :disabled="!$store.getters.isLoggedIn"
      >
        <div class="header-item">
          <ion-item class="greeting" lines="none">
            <ion-img :src="require('./assets/igp-logo.jpeg')" class="logo" />
          </ion-item>
        </div>

        <ion-content>
          <ion-list id="inbox-list" v-if="$store.state.loggedIn">
            <ion-menu-toggle
              auto-hide="false"
              v-for="(p, i) in appPages"
              :key="i"
            >
              <ion-item
                @click="selectedIndex = i"
                router-direction="root"
                :router-link="p.url"
                detail="false"
                class="hydrated"
                v-if="!p.action"
              >
                <ion-icon
                  slot="start"
                  :ios="p.iosIcon"
                  :md="p.mdIcon"
                ></ion-icon>
                <ion-label>{{ p.title }} {{ p.action }}</ion-label>
              </ion-item>

              <ion-item
                @click="p.action"
                detail="false"
                class="hydrated"
                v-else
              >
                <ion-icon
                  slot="start"
                  color="black"
                  :ios="p.iosIcon"
                  :md="p.mdIcon"
                ></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <!-- <div class="expiry">
            Plan gültig bis:
            {{
              new Date(
                $store.state.user.login_expiration_date * 1000
              ).toLocaleDateString("de-de")
            }}
          </div> -->
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>

    <ion-toast
      :is-open="updateExists"
      message="Eine neue Version der App ist verfügbar."
      color="primary"
      :buttons="[
        {
          side: 'end',
          text: 'Updaten',
          handler: () => refreshApp(),
        },
      ]"
    >
    </ion-toast>
  </IonApp>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonToast,
  IonImg,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import updates from "./mixins/updates";
import {
  homeOutline,
  listOutline,
  newspaperOutline,
  peopleOutline,
  personOutline,
  logOutOutline,
  globeOutline,
  createOutline,
  help,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonToast,
    IonImg,
  },
  mixins: [updates],
  methods: {
    menuVisible(event) {
      this.$store.dispatch("updateMenu", event.detail.visible);
    },
    async logout() {
      console.log("logout");
      await this.$store.dispatch("logout");
      this.$router.go("/login");
    },
    async presentErrorAlert() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Fehler",
        subHeader: "Irgendetwas hat nicht geklappt:",
        message: "Ihr Abo ist abgelaufen!",
        buttons: ["OK"],
      });
      await alert.present();

      await alert.onDidDismiss();
      this.logout();
    },
    checkValidSubscription() {
      if (!this.$store.state.loggedIn) return;
      const now = new Date();
      const expiry = new Date(
        this.$store.state.user.login_expiration_date * 1000
      );

      if (now.valueOf() < expiry.valueOf()) {
        console.log("subscription valid");
        setTimeout(() => this.checkValidSubscription(), 1000 * 60 * 60);
      } else {
        console.log("subscription expired");
        this.presentErrorAlert();
      }
    },
  },

  created() {
    this.checkValidSubscription();
    setTimeout(() => this.checkValidSubscription(), 1000 * 60 * 60);
  },

  computed: {
    appPages() {
      let pages = [
        {
          title: "Start",
          url: "/",
          iosIcon: homeOutline,
          mdIcon: homeOutline,
        },
        {
          title: "Länder",
          url: "/list/laender",
          iosIcon: globeOutline,
          mdIcon: globeOutline,
        },
        {
          title: "Themen",
          url: "/list/themen",
          iosIcon: listOutline,
          mdIcon: listOutline,
        },
        {
          title: "Kontakt",
          url: "/form",
          iosIcon: createOutline,
          mdIcon: createOutline,
        },
        {
          title: "Logout",
          iosIcon: logOutOutline,
          mdIcon: logOutOutline,
          action: this.logout,
        },
      ];
      return pages;
    },
  },

  setup() {
    const selectedIndex = ref(0);

    const route = useRoute();
    const router = useRouter();

    return {
      selectedIndex,
      router,
      homeOutline,
      listOutline,
      newspaperOutline,
      peopleOutline,
      personOutline,
      globeOutline,
      logOutOutline,
      createOutline,
      help,
      isSelected: (url) => (url === route.path ? "selected" : ""),
    };
  },
});
</script>

<style>
ion-split-pane {
  --side-max-width: 400px;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
  cursor: pointer;
}

ion-menu ion-item {
  cursor: pointer;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.mobile-header {
  background-color: white;
  border-bottom: 1px solid var(--ion-color-light);
}

ion-list-header {
  font-size: 18px;
  padding: 10px;
  align-items: center;
}

.list-header-inner {
  justify-content: center;
}

.header-item {
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  align-items: flex-end;
  background: url(/assets/img/dashbg.jpg);
  background-position: top center;
  background-size: cover;
}

.logo {
  height: 80px;
}

ion-item {
  --background: rgba(255, 255, 255, 0.8);
  border-radius: 0px !important;
}

.greeting-container {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.expiry {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  font-size: 10px;
}
</style>
