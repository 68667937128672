<template>
  <ion-content :fullscreen="true">
    <div id="login">
      <form class="login-form" @submit.prevent="login()">
        <ion-img :src="require('../assets/igp-logo.jpeg')" />
        <ion-item>
          <ion-label class="ion-margin-end">
            <ion-icon :icon="mailOutline"></ion-icon>
          </ion-label>
          <ion-input
            required
            v-model="email"
            type="email"
            placeholder="Email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="ion-margin-end">
            <ion-icon :icon="lockClosedOutline"></ion-icon>
          </ion-label>
          <ion-input
            required
            v-model="password"
            type="password"
            placeholder="Passwort"
          ></ion-input>
        </ion-item>
        <ion-button expand="block" type="submit" class="ion-margin-top">
          Anmelden
          <ion-icon slot="end" :icon="logInOutline"></ion-icon>
        </ion-button>
      </form>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonIcon,
  IonImg,
  alertController,
} from "@ionic/vue";
import { logInOutline, mailOutline, lockClosedOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonIcon,
    IonImg,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMsg: ""
    };
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    return {
      logInOutline,
      mailOutline,
      lockClosedOutline,
      isOpenRef,
      setOpen,
    };
  },
  methods: {
    async presentErrorAlert(err) {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Fehler",
        subHeader: "Irgendetwas hat nicht geklappt:",
        message: err,
        buttons: ["OK"],
      });
      await alert.present();

      await alert.onDidDismiss();
    },
    async login() {
      console.log("login");
      let email = this.email;
      let password = this.password;
      try {
        const user = await this.$http.get("igpapp/v1/user-data/", {
          auth: {
            username: email,
            password: password,
          },
        });
        await this.$store.dispatch("login", user.data);
        this.$router.push("/");
      } catch (err) {
        console.log(err.response);
        // alert(err.response.data.message)
        this.presentErrorAlert(err.response.data.message);
      }
    },
    async setOpenPw() {
      const alert = await alertController.create({
        header: "Passwort zurücksetzen",
        subHeader: "Bist du sicher, dass Du Dein Passwort zurücksetzen willst?",
        message:
          "Du bekommst anschließend eine Email mit weiteren Informationen",
        inputs: [
          {
            name: "email",
            type: "email",
            value: this.email,
            placeholder: "Bitte gib deine Email Addresse an.",
          },
        ],
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
          },
          {
            text: "Zurücksetzen",
            handler: (alertData) => {
              this.resetPW(alertData);
            },
          },
        ],
      });
      return alert.present();
    },
  },
});
</script>

<style scoped>
#login {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  height: 100%;
  background: url(/assets/igp-background.jpeg);
  background-position: center center;
  background-size: cover;
}

.login-form {
  width: 350px;
  max-width: 80vw;
  margin-top: -50px;
  background-color: white;
  padding: 20px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
  display: flex;
  flex-direction: column;
}

ion-img {
  height: 3.5rem;
  margin-bottom: 25px;
  margin-top: 15px;
}

.button {
  margin-top: 35px;
}

ion-note {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}
</style>
