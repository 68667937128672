import { createRouter, createWebHistory } from '@ionic/vue-router';
import List from '../views/List.vue'
import Dash from '../views/Dash.vue'
import Login from '../views/Login.vue'
import Form from '../views/Form.vue'
import store from "../store";

const routes = [
  {
    path: '/',
    redirect: '/dash',
    meta: {
			requiresAuth: true
		}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dash',
    name: 'Dash',
    component: Dash,
    meta: {
			requiresAuth: true
		}
  },
  {
    path: '/form',
    name: 'Form',
    component: Form,
    meta: {
			requiresAuth: true
		}
  },
  {
    path: '/list/:route',
    name: 'List',
    component: List,
    meta: {
			requiresAuth: true
		}
  },
  {
    path: '/detail/:type/:id',
    component: () => import('../views/Detail.vue'),
    meta: {
			requiresAuth: true
		}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next();
			return;
		}
		next("/login");
    return;
	}
	next();
});

export default router
