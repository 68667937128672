<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button href="/dash">
            <ion-icon :icon="apps"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list class="ion-padding-horizontal">
        <MessageListItem
          v-for="topic in topics"
          :key="topic.id"
          :topic="topic"
        />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonList,
  IonButtons,
  // IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import MessageListItem from "@/components/MessageListItem.vue";
import { apps, globe } from "ionicons/icons";

export default {
  name: "List",
  data() {
    return {
      topics: [],
    };
  },

  computed: {
    title() {
      const route = this.$route.params.route;
      if (route === "laender") return "Länder";
      else return "Themen";
    },
  },
  methods: {
    async ionViewWillEnter() {
      this.fetchData(this.$route.params.route);
    },
    async fetchData(route) {
      const { data } = await this.$http.get(`wp/v2/${route}?per_page=100`);
      data.sort((a, b)=> a.slug.localeCompare(b.slug))
      this.topics = data;
    },
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        const { route } = newValue;
        if(!route) return
        this.fetchData(route);
      },
      immediate: true,
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonList,
    IonButtons,
    // IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    MessageListItem,
  },
  setup() {
    return {
      apps,
      globe,
    };
  },
};
</script>